import React     from "react"
import PropTypes from "prop-types"
import Helmet    from "react-helmet"

import Header          from "../components/header"

import "../assets/css/magnific-popup.css"
import "../assets/bootstrap/css/bootstrap.min.css"
import "../assets/font-awesome/css/fontawesome-all.min.css"
import "../assets/css/odometer-theme-default.css"
import "../assets/css/owl.carousel.min.css"
import "../assets/css/index.css"
import AppleTouchIcon  from "../assets/images/favicon/apple-touch-icon.png"
import Favicon32       from "../assets/images/favicon/favicon-32x32.png"
import Favicon16       from "../assets/images/favicon/favicon-16x16.png"
import SafariPinnedTab from "../assets/images/favicon/safari-pinned-tab.svg"

class Layout extends React.Component {
  componentDidMount() {
    require("../assets/js/odometer.min")
    require("../assets/js/popper.min.js")
    require("../assets/bootstrap/js/bootstrap.min.js")
    require("../assets/js/custom.hero.js")
    require("../assets/js/custom.js")
  }

  render() {
    const { children, data } = this.props
    return ( <div>
        <Helmet
          title="Maxime Nguyen - Frontend Developer | Belgium"
          meta={ [
            { name: "description", content: "Sample" },
            { name: "keywords", content: "sample, something" },
            { name: "msapplication-TileColor", content: "#ffc40d" },
            { name: "theme-color", content: "#ffffff" },
            { name: "google-site-verification", content: "8VieruQYOm1xYmYSWMS5ldUpAeC8sOjvuUmviOtV56U" },
          ] }
          link={ [
            { rel: "stylesheet", href: "https://fonts.googleapis.com/css?family=Roboto:400,500,700" },
            { rel: "apple-touch-icon", sizes: "180x180", href: AppleTouchIcon },
            { rel: "icon", type: "image/png", sizes: "32x32", href: Favicon32 },
            { rel: "icon", type: "image/png", sizes: "16x16", href: Favicon16 },
            { rel: "mask-icon", href: SafariPinnedTab, color: "#5bbad5" },
          ] }
        >
          <body data-spy="scroll" data-target=".navbar" />
        </Helmet>
        <Header siteTitle="Maxime Nguyen - Frontend Developer | Belgium" />
        <div>
          { children }
        </div>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
